import { React, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { Card, Container, Row, Col } from "react-bootstrap";

import "./CardForm.css";
import "./Contact.css";
import toast, { Toaster } from "react-hot-toast";

function Contact({ phone }) {
  const [uid, setUid] = useState(uuidv4());
  const [total, setTotal] = useState(Math.floor(Math.random() * 12) + 1);

  const clipboardCopy = () => {
    toast.success("טקסט הועתק");
    navigator.clipboard.writeText(uid.split("-")[0]);
  };
  return (
    <Row className="card-row " dir="rtl">
      <Col align="center">
        <Card>
          <Card.Header className="top-txt">
            יש לנו את זה <i className="em em-sunglasses"></i>
          </Card.Header>
          <Container>
            <Row className="frst-row">
              <Col>
                <i className="fa-solid fa-check"></i> הסוכן החכם איתר עבורך{" "}
                <span className="highlighted-stats space-left">{total}</span>{" "}
                משרות!
              </Col>
            </Row>
            <Row className="frst-row">
              <Col>
                <i className="fa-solid fa-check"></i> מספר הפניה הייחודי שלך
                הינו{" "}
                <span className="highlighted-stats">
                  {uid.split("-")[0]}
                  <i
                    className="fa-regular fa-copy copy-icon"
                    onClick={() => {
                      clipboardCopy();
                    }}
                  ></i>
                </span>
              </Col>
            </Row>
            <Row className="frst-row">
              <Col>
                <i className="fa-solid fa-check space-left"></i> יועץ קריירה
                יצור עמך קשר במספר טלפון{" "}
                <span className="highlighted-stats">
                  {phone ? phone : "מספר לא נקלט"}
                </span>{" "}
                בתוך 3 ימי עסקים
              </Col>
            </Row>
            <Row className="frst-row">
              <Col>
                <span>רוצה להתחיל מחר בבוקר? דבר איתנו </span>
                <a href="https://api.whatsapp.com/send?phone=+972547583383">
                  <i className="fab fa-whatsapp whats-link"></i>
                </a>{" "}
              </Col>

              <Toaster position="top-center" />
            </Row>
          </Container>
        </Card>
      </Col>
    </Row>
  );
}

export default Contact;
