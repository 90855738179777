import { React, useEffect, useState } from "react";

import { Card, Button, Row, Col, Badge, Spinner } from "react-bootstrap";

import "./CardForm.css";
import "./Final.css";
import "react-step-progress-bar/styles.css";

import { Step, ProgressBar } from "react-step-progress-bar";

function Final({ setProgress, progress, phone, data }) {
  const [select, setSelect] = useState(null);
  const [loading, setLoading] = useState(true);
  const [progload, setProgload] = useState(100);
  const [loader, setLoader] = useState([]);
  const delay = async (ms) => new Promise((res) => setTimeout(res, ms));

  useEffect(() => {
    const setDealy = async () => {
      await delay(15000);
      setProgress(5);
    };
    setDealy();
  }, [delay, setLoading, setProgress]);

  const handleClicks = async () => {
    setLoading(true);
    setProgload(97);
  };

  useEffect(() => {
    if (loading) {
      setTimeout(function () {
        if (loader.length === 0) {
          let newLoader = loader;
          newLoader.push(".");
          setLoader([...newLoader]);

          return;
        }
        if (loader.length === 1) {
          let newLoader = loader;
          newLoader.push(".");
          setLoader([...newLoader]);

          return;
        }
        if (loader.length === 2) {
          let newLoader = loader;
          newLoader.push(".");
          setLoader([...newLoader]);

          return;
        }
        if (loader.length === 3) {
          let newLoader = loader;
          newLoader.push(
            <i className="fa-solid fa-magnifying-glass fa-spin"></i>
          );
          setLoader([...newLoader]);

          return;
        }
        if (loader.length === 4) {
          setLoader([]);

          return;
        }
      }, 400);
    }
  }, [loader, loading]);

  return (
    <Row className="card-row " dir="rtl">
      <Col align="center">
        <Card>
          <Card.Header>
            <ProgressBar
              filledBackground="linear-gradient(to right, #e9c46a, #33415c)"
              percent={progload}
              className="prog-bar-container"
            >
              <Step transition="scale">
                {({ accomplished, index }) => (
                  <div
                    className={`transitionStep ${
                      accomplished ? "accomplished" : null
                    }`}
                  >
                    <i className="fa-solid fa-check icon-question fin-check"></i>
                  </div>
                )}
              </Step>
              <Step transition="scale">
                {({ accomplished, index }) => (
                  <div
                    className={`transitionStep ${
                      accomplished ? "accomplished" : null
                    }`}
                  >
                    <i className="fa-solid fa-check icon-question fin-check"></i>
                  </div>
                )}
              </Step>
              <Step transition="scale">
                {({ accomplished, index }) => (
                  <div
                    className={`transitionStep ${
                      accomplished ? "accomplished" : null
                    }`}
                  >
                    <i className="fa-solid fa-check icon-question fin-check"></i>
                  </div>
                )}
              </Step>
            </ProgressBar>
          </Card.Header>
          <Card.Body className="final-row">
            <Card.Title>
              מאתר משרות
              {loader.map((item, i) => (
                <span className="loading-txt" key={i}>
                  {item}
                </span>
              ))}
            </Card.Title>

            <div dir="rtl">
              <Row>
                <Col align="center">
                  {data["payment"]["a"] && (
                    <Badge className="badge-comp shaking">
                      10,000₪ - 6,000₪{" "}
                    </Badge>
                  )}
                  {data["payment"]["b"] && (
                    <Badge className="badge-comp shaking">
                      20,000₪ - 10,000₪
                    </Badge>
                  )}
                  {data["payment"]["c"] && (
                    <Badge className="badge-comp shaking">20,000₪ +</Badge>
                  )}
                </Col>
              </Row>
              <Row dir="rtl">
                <Col align="center">
                  {data.jobs.map((item, i) => (
                    <Badge
                      bg="secondary"
                      key={i}
                      className="badge-comp shaking"
                    >
                      <span> {item.name}</span>
                      {"    "}
                      <span className="badge-icon">{item.icon}</span>
                    </Badge>
                  ))}
                </Col>
              </Row>
              <Row>
                <Col align="center">
                  {data["home"]["a"] && (
                    <Badge className="badge-comp shaking">
                      עבודה מהבית
                      <span className="badge-icon-left">
                        <i className="fa-solid fa-house-laptop"></i>
                      </span>
                    </Badge>
                  )}
                  {data["home"]["b"] && (
                    <Badge className="badge-comp shaking">
                      עבודה במשרד
                      <span className="badge-icon-left">
                        <i className="fa-solid fa-building-circle-check"></i>
                      </span>
                    </Badge>
                  )}
                  {data["home"]["c"] && (
                    <Badge className="badge-comp shaking">
                      עבודה היברידית
                      <span className="badge-icon-left">
                        <i className="fa-solid fa-calendar-check"></i>
                      </span>
                    </Badge>
                  )}
                </Col>
              </Row>
            </div>

            {!loading ? (
              <Button
                variant="primary"
                className="enter-btn"
                disabled={select ? false : true}
                onClick={() => handleClicks()}
              >
                המשך
              </Button>
            ) : (
              <Spinner animation="border" className="loading-spinner" />
            )}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

export default Final;
