import { React, useState, useEffect } from "react";
import { Card, Button, Row, Col, Badge, Spinner } from "react-bootstrap";
import api from "./DAL/api.js";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import "./CardFormTwo.css";
import "react-step-progress-bar/styles.css";

import { Step, ProgressBar } from "react-step-progress-bar";

function CardFormTwo({ setProgress, progress, setData, data }) {
  const [select, setSelect] = useState(null);
  const [jobPicker, setJobPicker] = useState([]);
  const [jobs, setHotJobs] = useState(api.getJobs());
  const [loading, setLoading] = useState(false);
  const [progload, setProgload] = useState(49);
  const delay = async (ms) => new Promise((res) => setTimeout(res, ms));

  const jobHandler = (i) => {
    if (progload >= 51) {
      return;
    }
    if (jobPicker.length <= 2) {
      let newJobPicker = jobPicker;
      if (!newJobPicker.find((item) => item.name === jobs[i].name)) {
        newJobPicker.push(jobs[i]);
        setJobPicker([...newJobPicker]);
      }
    }
  };

  useEffect(() => {
    if (progload === 100) {
      setProgress(3);
    }
    if (progload > 50 && progload < 100) {
      setTimeout(function () {
        let newProgload = progload + 1;
        setProgload(newProgload);
      }, 120);
    }
  }, [progload, setProgload, setProgress]);

  const handleClicks = () => {
    setLoading(true);
    setProgload(51);
    let newData = { ...data, jobs: [...jobPicker] };
    setData(newData);
  };

  const remFinalists = (item) => {
    if (progload >= 51) {
      return;
    }
    let newJobPicker = jobPicker;
    const filtered = newJobPicker.filter((j) => j.name !== item.name);
    setJobPicker([...filtered]);
  };

  return (
    <Row className="card-row" dir="rtl">
      <Col align="center">
        <Card>
          <Card.Header>
            {" "}
            <ProgressBar
              filledBackground="linear-gradient(to right, #e9c46a, #33415c)"
              percent={progload}
              className="prog-bar-container"
            >
              <Step transition="scale">
                {({ accomplished, index }) => (
                  <div
                    className={`transitionStep ${
                      accomplished ? "accomplished" : null
                    }`}
                  >
                    <i className="fa-solid fa-check icon-question fin-check"></i>
                  </div>
                )}
              </Step>
              <Step transition="scale">
                {({ accomplished, index }) => (
                  <div
                    className={`transitionStep ${
                      accomplished ? "accomplished" : null
                    }`}
                  >
                    {progload > 50 ? (
                      <i className="fa-solid fa-check icon-question fin-check"></i>
                    ) : (
                      <i className="fa-solid fa-briefcase icon-question"></i>
                    )}
                  </div>
                )}
              </Step>
              <Step transition="scale">
                {({ accomplished, index }) => (
                  <div
                    className={`transitionStep ${
                      accomplished ? "accomplished" : null
                    }`}
                  >
                    <i className="fa-solid fa-house-chimney icon-question"></i>
                  </div>
                )}
              </Step>
            </ProgressBar>
          </Card.Header>
          <Card.Body>
            <Card.Title>
              תחום עיסוק (עד 3){" "}
              <i className="fa-solid fa-briefcase icon-question"></i>
            </Card.Title>

            <div dir="rtl">
              <Row dir="rtl">
                <Col align="center">
                  <SimpleBar
                    style={{ maxHeight: 80 }}
                    className="scroll-bar"
                    forceVisible="y"
                    autoHide={false}
                  >
                    {jobs.map((item, i) => (
                      <Badge
                        bg="secondary"
                        key={i}
                        className={
                          jobPicker.find((j) => j.name === item.name)
                            ? "alt-badge-grey"
                            : "badge-comp"
                        }
                        onClick={() => {
                          jobHandler(i);
                        }}
                      >
                        <span> {item.name}</span>
                        {"    "}
                        <span className="badge-icon">{item.icon}</span>
                      </Badge>
                    ))}
                  </SimpleBar>
                </Col>
              </Row>
              <hr></hr>
              <Row className="picked-row">
                <Col>
                  {jobPicker.map((item, i) => (
                    <Badge bg="secondary" key={i} className="alt-badge shaking">
                      <span> {item.name}</span>
                      {"    "}
                      <i
                        className="fas fa-trash-alt"
                        onClick={() => remFinalists(item)}
                      ></i>
                    </Badge>
                  ))}
                </Col>
              </Row>
            </div>
            {!loading ? (
              <Button
                variant="primary"
                className="enter-btn"
                disabled={jobPicker.length >= 1 ? false : true}
                onClick={() => handleClicks()}
              >
                המשך
              </Button>
            ) : (
              <Spinner animation="border" className="loading-spinner" />
            )}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

export default CardFormTwo;
