const popJobs = [
  {
    name: "אבטחה ושמירה",
    total: 110,
    icon: <i className="fa-solid fa-building-lock"></i>,
  },
  {
    name: "אדמיניסטרציה",
    total: 89,
    icon: <i className="fa-solid fa-thumbtack"></i>,
  },
  {
    name: "אנשי מקצוע",
    total: 203,
    icon: <i className="fa-solid fa-wrench"></i>,
  },
  {
    name: "בניין ותשתיות",
    total: 310,
    icon: <i className="fa-solid fa-trowel-bricks"></i>,
  },
  {
    name: "חשמל ואלקטרוניקה",
    total: 87,
    icon: <i className="fa-solid fa-plug-circle-bolt"></i>,
  },
  {
    name: "תעשייה וייצור",
    total: 98,
    icon: <i className="fa-solid fa-industry"></i>,
  },
  {
    name: "כללי / ללא נסיון",
    total: 280,
    icon: <i className="fa-solid fa-globe"></i>,
  },
  {
    name: "מחשבים ורשתות",
    total: 320,
    icon: <i className="fa-solid fa-network-wired"></i>,
  },
  {
    name: "נהגים ושליחים",
    total: 48,
    icon: <i className="fa-solid fa-truck"></i>,
  },
  {
    name: "עבודה מהבית",
    total: 410,
    icon: <i className="fa-solid fa-house-circle-check"></i>,
  },
];

const getJobs = () => {
  return popJobs;
};

const api = {
  getJobs,
};

export default api;
