import { React, useEffect, useState } from "react";
import { Card, Button, Row, Col, ListGroup, Spinner } from "react-bootstrap";
import "./CardForm.css";
import "react-step-progress-bar/styles.css";

import { Step, ProgressBar } from "react-step-progress-bar";

function CardForm({ setProgress, progress, setData, data }) {
  const [select, setSelect] = useState(null);
  const [loading, setLoading] = useState(false);
  const [progload, setProgload] = useState(0);

  useEffect(() => {
    if (progload > 0 && progload < 50) {
      setTimeout(function () {
        let newProgload = progload + 1;
        setProgload(newProgload);
      }, 120);
      if (progload === 49) {
        setProgress(2);
      }
    }
  }, [progload, setProgload, setProgress]);

  const handleClicks = async () => {
    setLoading(true);
    setProgload(1);
    let newData = { ...data, payment: { ...data["payment"], [select]: true } };
    setData(newData);
  };

  return (
    <Row className="card-row" dir="rtl">
      <Col align="center">
        <Card>
          <Card.Header>
            <ProgressBar
              filledBackground="linear-gradient(to right, #e9c46a, #33415c)"
              percent={progload}
              className="prog-bar-container"
            >
              <Step transition="scale">
                {({ accomplished, index }) => (
                  <div
                    className={`transitionStep ${
                      accomplished ? "accomplished" : null
                    }`}
                  >
                    {progload > 1 ? (
                      <i className="fa-solid fa-check icon-question fin-check"></i>
                    ) : (
                      <i className="fa-solid fa-coins icon-question"></i>
                    )}
                  </div>
                )}
              </Step>
              <Step transition="scale">
                {({ accomplished, index }) => (
                  <div
                    className={`transitionStep ${
                      accomplished ? "accomplished" : null
                    }`}
                  >
                    <i className="fa-solid fa-briefcase icon-question"></i>
                  </div>
                )}
              </Step>
              <Step transition="scale">
                {({ accomplished, index }) => (
                  <div
                    className={`transitionStep ${
                      accomplished ? "accomplished" : null
                    }`}
                  >
                    <i className="fa-solid fa-house-chimney icon-question"></i>
                  </div>
                )}
              </Step>
            </ProgressBar>
          </Card.Header>
          <Card.Body>
            <Card.Title>
              גובה השכר הרצוי?{" "}
              <i className="fa-solid fa-coins icon-question"></i>{" "}
            </Card.Title>

            <div dir="rtl">
              <Row dir="rtl">
                <Col>
                  {" "}
                  <ListGroup dir="ltr">
                    <ListGroup.Item
                      disabled={progload >= 1 && select !== "a" ? true : false}
                      className={
                        select === "a" ? "list-item-active" : "list-item"
                      }
                      onClick={() => setSelect("a")}
                    >
                      6,000 - 10,000
                    </ListGroup.Item>
                    <ListGroup.Item
                      disabled={progload >= 1 && select !== "b" ? true : false}
                      className={
                        select === "b" ? "list-item-active" : "list-item"
                      }
                      onClick={() => setSelect("b")}
                    >
                      10,000 - 20,000
                    </ListGroup.Item>
                    <ListGroup.Item
                      disabled={progload >= 1 && select !== "c" ? true : false}
                      className={
                        select === "c" ? "list-item-active" : "list-item"
                      }
                      onClick={() => setSelect("c")}
                    >
                      20,000 +
                    </ListGroup.Item>
                  </ListGroup>
                </Col>
              </Row>
            </div>

            {!loading ? (
              <Button
                variant="primary"
                className="enter-btn"
                disabled={select ? false : true}
                onClick={() => handleClicks()}
              >
                המשך
              </Button>
            ) : (
              <Spinner animation="border" className="loading-spinner" />
            )}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

export default CardForm;
