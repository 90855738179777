import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./TopHeader.css";
import logo from "./DAL/media/logo.png";
import logo2 from "./DAL/media/logo192.png";

function TopHeader() {
  return (
    <Container fluid className="container-top">
      <Row>
        <Col align="left">
          <img alt="logo" src={logo2} className="logo-img" />
        </Col>

        <Col align="center" className="menu-divider">
          {" "}
          מעסיקים
        </Col>
      </Row>
    </Container>
  );
}

export default TopHeader;
