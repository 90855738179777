import { React, useState } from "react";

import { useSearchParams } from "react-router-dom";
import "./App.css";
import TopHeader from "./TopHeader";
import FooterBottom from "./FooterBottom";
import CardForm from "./CardForm";
import CardFormTwo from "./CardFormTwo";
import CardFormThree from "./CardFormThree";
import HotJobs from "./HotJobs";
import Hero from "./Hero";
import Final from "./Final";
import Contact from "./Contact";
import { Container } from "react-bootstrap";

function App() {
  let [searchParams, setSearchParams] = useSearchParams();
  let phone = searchParams.get("lead");
  const [progress, setProgress] = useState(1);
  const [data, setData] = useState({
    payment: { a: false, b: false, c: false },
    jobs: [],
    home: { a: false, b: false, c: false },
  });

  return (
    <Container className="app-container">
      <TopHeader />
      <Hero />
      {progress === 1 && (
        <CardForm
          setProgress={setProgress}
          progress={progress}
          setData={setData}
          data={data}
        />
      )}
      {progress === 2 && (
        <CardFormTwo
          setProgress={setProgress}
          progress={progress}
          setData={setData}
          data={data}
        />
      )}
      {progress === 3 && (
        <CardFormThree
          setProgress={setProgress}
          progress={progress}
          setData={setData}
          data={data}
        />
      )}
      {progress === 4 && (
        <Final setProgress={setProgress} data={data} phone={phone} />
      )}
      {progress === 5 && (
        <Contact setProgress={setProgress} data={data} phone={phone} />
      )}
      <HotJobs />
      <FooterBottom />
    </Container>
  );
}

export default App;
