import { React, useState } from "react";
import { Container, Row, Col, Badge } from "react-bootstrap";
import "./HotJobs.css";
import api from "./DAL/api.js";

function HotJobs() {
  const [jobs, setHotJobs] = useState(api.getJobs());

  return (
    <Container className="jobs-container">
      <Row className="txt-row">
        <Col align="center">
          <span>
            <i className="em em-fire"></i>
          </span>
          <span className="pop-txt">משרות חמות</span>
        </Col>
      </Row>
      <Row>
        <Col align="center">
          {jobs.map((item, i) => (
            <Badge bg="secondary" key={i} className="badge-comp">
              <span className="badge-total"> {`(${item.total})`}</span>
              <span> {item.name}</span>
              <span className="badge-icon">{item.icon}</span>
            </Badge>
          ))}
        </Col>
      </Row>
    </Container>
  );
}

export default HotJobs;
