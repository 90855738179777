import React from "react";
import { Container, Row, Col, Navbar } from "react-bootstrap";
import "./FooterBottom.css";

function FooterBottom() {
  return (
    <Navbar expand="lg" fixed="bottom" className="footer" dir="ltr">
      <Container>
        <Navbar.Brand className="navbar-icons-container">
          <Row>
            <Col align="center">
              <i
                className="fa fa-facebook navbar-icons-container"
                aria-hidden="true"
              ></i>
              <i
                className="fa fa-instagram navbar-icons-container"
                aria-hidden="true"
              ></i>
              <i
                className="fa fa-twitter navbar-icons-container"
                aria-hidden="true"
              ></i>
            </Col>
          </Row>
        </Navbar.Brand>
      </Container>
    </Navbar>
  );
}

export default FooterBottom;
