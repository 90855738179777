import { React, useState, useEffect } from "react";
import { Container, Row, Col, Badge } from "react-bootstrap";
import "./Hero.css";

function Hero() {
  const [count, setCount] = useState({ jobs: 1, jobsStr: "1" });

  useEffect(() => {
    function nextFibo(n) {
      let a = (n * (1 + Math.sqrt(5))) / 2.0;
      return Math.round(a);
    }
    let newCount = count;
    if (newCount.jobs < 50000) {
      setTimeout(function () {
        setCount({
          jobs: nextFibo(newCount.jobs),
          jobsStr: String(nextFibo(newCount.jobs)),
        });
      }, 250);
    }
  }, [count]);

  return (
    <Container className="hero-container">
      <Row>
        <Col align="center">
          <span className="top-txt">אתר הדרושים שעובד בשבילך</span>
        </Col>
      </Row>
      <Row>
        <Col align="center">
          {count.jobsStr.split("").map((digit, i) => (
            <Badge className="digit-badge" key={i}>
              {digit}
            </Badge>
          ))}
        </Col>
      </Row>
      <Row>
        <Col align="center">
          <i className="em em-handshake"></i>
          <span className="under-txt">משרות ממתינות לך</span>
        </Col>
      </Row>
    </Container>
  );
}

export default Hero;
